import React from "react";
import { Button, Divider, Collapse } from "@mui/material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Footer from "./Footer";

const SalesPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        className="sales-page-header"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <h1
          style={{
            padding: "10px 20px",
            marginTop: "0px",
            marginBottom: "0px",
            fontSize: "64px",
          }}
        >
          Virtuous
        </h1>
        <h2>Coming soon!</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Feel free to contact us at
          <br />
          <a href="mailto:support@usevirtuous.com">support@usevirtuous.com</a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SalesPage;
