import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goHome = (event) => {
    navigate("/", {
      replace: true,
      state: { from: location },
    });
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        className="section"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "100px 5%",
        }}
      >
        <div
          style={{
            marginBottom: "8px",
            color: "rgba(118, 126, 173, 0.59)",
            fontSize: "24px",
            fontWeight: "700",
            letterSpacing: "1px",
            cursor: "pointer",
          }}
          onClick={goHome}
        >
          Virtuous
        </div>
        <h1
          style={{
            maxWidth: "720px",
            marginTop: "0px",
            color: "#333",
            fontSize: "42px",
            lineHeight: "51px",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          Privacy Policy
        </h1>
        <p>Last Updated: April 6, 2024 </p>
        <h4>Introduction</h4>
        <p style={{ paddingLeft: "40px" }}>
          Welcome to Virtuous, the app designed to help you become more
          virtuous. Your privacy is important to us, and this Privacy Policy
          explains how we collect, use, and share information about you, as well
          as the choices you have with respect to that information.
        </p>
        <h4>Information We Collect</h4>
        <ul>
          <li style={{ paddingBottom: "10px" }}>
            Email Address: We collect your email address when you sign up for
            our service. We use this to send you daily performance reports and,
            if you opt-in, promotional materials that may be of interest to you.
          </li>
          <li>
            Usage Data: We automatically collect information about how you use
            our app, such as the types of logs you create and your engagement
            with the app. This helps us improve our service and personalize your
            experience.
          </li>
        </ul>
        <h4>How We Use Your Information</h4>
        <ul>
          <li style={{ paddingBottom: "10px" }}>
            To Provide and Improve Our Service: We use your information to
            provide the services you request, improve our app's functionality,
            and address app performance issues.
          </li>
          <li>
            Communications: We may use your email address to send you
            service-related notices. Subject to your consent, we may also send
            you promotional emails about new app features, special offers, and
            other news about our app.
          </li>
        </ul>
        <h4>Sharing Your Information</h4>
        <p style={{ paddingLeft: "40px" }}>
          We do not share your personal information with third parties except as
          described in this policy or with your permission. We may share
          non-personally identifiable information with analytics and technology
          partners to help us improve our service.
        </p>
        <h4>Your Choices And Control</h4>
        <ul style={{ maxWidth: "800px" }}>
          <li style={{ paddingBottom: "10px" }}>
            Email Communications: You can opt-out of receiving promotional
            emails from us by following the unsubscribe link in those emails.
          </li>
          <li>
            Data Access and Deletion: You may request access to or deletion of
            your personal information by contacting us at{" "}
            <a href="mailto:support@usevirtuous.com">support@usevirtuous.com</a>
          </li>
        </ul>
        <h4>Changes To This Privacy Policy</h4>
        <p style={{ paddingLeft: "40px" }}>
          We may update this Privacy Policy from time to time. If we make
          changes, we will notify you by revising the date at the top of the
          policy and, in some cases, we may provide you with more prominent
          notice (such as adding a statement to our homepage or sending you a
          notification).
        </p>
        <h4>Contact Us</h4>
        <p style={{ paddingLeft: "40px" }}>
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <a href="mailto:support@usevirtuous.com">support@usevirtuous.com</a>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
