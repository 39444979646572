import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect
} from "react-router-dom";
import React, { useState, useEffect, useParams } from "react";
import TermsOfUse from "./Components/TermsOfUse";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import SalesPage from "./Components/SalesPage";

function App() {
  return (
      <Router>
        <Routes>
          <Route exact path="/" element={(<SalesPage />)}></Route>
          <Route path='/terms' element={<TermsOfUse/>}/>
          <Route path='/privacy' element={<PrivacyPolicy/>}/>
        </Routes>
      </Router>
  );
}

export default App;