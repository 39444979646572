import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Footer from "./Footer";

const TermsOfUse = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goHome = (event) => {
    navigate("/", {
      replace: true,
      state: { from: location },
    });
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        className="section"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "100px 5%",
        }}
      >
        <div
          style={{
            marginBottom: "8px",
            color: "rgba(118, 126, 173, 0.59)",
            fontSize: "24px",
            fontWeight: "700",
            letterSpacing: "1px",
            cursor: "pointer",
          }}
          onClick={goHome}
        >
          Virtuous
        </div>
        <h1
          style={{
            maxWidth: "720px",
            marginTop: "0px",
            color: "#333",
            fontSize: "42px",
            lineHeight: "51px",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          Terms of Use
        </h1>
        <p>Last Updated: April 6, 2024 </p>
        <h4>Introduction</h4>
        <p style={{ paddingLeft: "40px" }}>
          Welcome to Virtuous, the app designed to help you become more
          virtuous. By accessing or using our app, you agree to be bound by
          these Terms of Use ("Terms"). If you do not agree to these terms,
          please do not use our app.
        </p>
        <h4>Use of Our Service</h4>
        <p style={{ paddingLeft: "40px" }}>
          Virtuous provides a platform for users to create daily logs. You are
          responsible for the content of your logs and for using the app in a
          manner that complies with all applicable laws and regulations.
        </p>
        <h4>Accounts and Registration</h4>
        <p style={{ paddingLeft: "40px" }}>
          To access some features of the app, you must register for an account.
          When you register, you agree to provide accurate, current, and
          complete information and to update this information to keep it
          accurate, current, and complete.
        </p>
        <h4>Privacy</h4>
        <p style={{ paddingLeft: "40px" }}>
          Your privacy is important to us. Our Privacy Policy explains how we
          collect, use, and share information about you. By using our app, you
          agree to the terms of our Privacy Policy.
        </p>
        <h4>Communications</h4>
        <p style={{ paddingLeft: "40px" }}>
          By creating an account, you agree to receive certain communications in
          connection with our app. For example, you might receive performance
          reports or promotional emails if you opt-in to receiving them.
        </p>
        <h4>Content and Conduct</h4>
        <ul>
          <li>
            Your Content: You retain all rights in the logs that you create, but
            you grant us a license to use, store, and display those logs in
            connection with providing and improving the app.
          </li>
          <li>
            Prohibited Conduct: You agree not to engage in conduct that is
            harmful, fraudulent, deceptive, or offensive. You agree not to
            violate any law or regulation.
          </li>
        </ul>
        <h4>Intellectual Property</h4>
        <p style={{ paddingLeft: "40px" }}>
          The app and its original content, features, and functionality are and
          will remain the exclusive property of Darren Hoffmann-Marks.
        </p>
        <h4>Termination</h4>
        <p style={{ paddingLeft: "40px" }}>
          We may terminate or suspend your access to our app immediately,
          without prior notice or liability, for any reason, including without
          limitation if you breach the Terms.
        </p>
        <h4>Changes to the Terms</h4>
        <p style={{ paddingLeft: "40px" }}>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will provide at
          least 30 days' notice prior to any new terms taking effect.
        </p>
        <h4>Contact Us</h4>
        <p style={{ paddingLeft: "40px" }}>
          If you have any questions about these Terms, please contact us at{" "}
          <a href="mailto:support@usevirtuous.com">support@usevirtuous.com</a>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
